const i18n = {
    de: {
        headline: 'Ihre Sicherungs&shy;belegungskarte',
        copy: 'In den BMW Fahrzeugen ab dem Baujahr 2019 ist die Sicherungsbelegungskarte nicht mehr in Papierform beigefügt. Bei Bedarf können Sie die Sicherungsbelegungskarte für Ihr Fahrzeug hier als PDF-Dokument herunterladen.',
        subheadline: 'Bitte geben Sie Ihre Fahrzeug-Identifizierungsnummer ein.',
        'form-example': 'Bitte FIN eingeben (z.B. WBS1H9C36HV888591)',
        'button-dl': 'PDF herunterladen',
        'data-info': 'Bitte geben Sie die vollständige 17-stellige Fahrzeug-Identifizierungsnummer (FIN) Ihres BMW ein (z.B. WMBHA61000BB07121). Die FIN befindet sich im Fahrzeugschein, im Motorraum oder an der Windschutzscheibe links unten.',
        'no-pdf-for-vin': 'Für die angegebene Fahrzeug-Identifizierungsnummer ist leider keine Online-Sicherungsbelegungskarte verfügbar. Bitte wenden Sie sich an Ihren BMW Partner.',
        'no-vehicle-for-vin': 'Leider konnten wir die Fahrzeug-Identifizierungsnummer keinem BMW Fahrzeug zuordnen. Bitte überprüfen Sie Ihre Eingabe.',
        'server-error': 'Leider können wir Ihre Anfrage zur Zeit nicht bearbeiten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    },
    en: {
        headline: 'Your fuse assignment plan',
        copy: 'The fuse assignment plan will no longer be provided in paper form for BMW vehicles from 2019 onwards. You can download the fuse assignment plan for your vehicle as a PDF document here if needed.',
        subheadline: 'Please enter your vehicle identification number',
        'form-example': 'Please enter VIN (e.g. WBS1H9C36HV888591)',
        'button-dl': 'Download PDF',
        'data-info': 'Please enter the complete 17-digit vehicle identification number (VIN) for your BMW (e.g. WMBHA61000BB07121). The VIN is located on the vehicle registration, in the engine compartment or on the windscreen at the bottom left.',
        'no-pdf-for-vin': 'Unfortunately there is no online fuse assignment plan available for the specified vehicle identification number. Please contact your BMW partner.',
        'no-vehicle-for-vin': 'Unfortunately we could not assign the vehicle identification number to a BMW vehicle. Please check your entry.',
        'server-error': 'Unfortunately we cannot process your request at this time. Please try again later.',
    },
    fr: {
        headline: 'Votre schéma de l\'emplacement des fusibles',
        copy: 'À partir de l\'année 2019, les véhicules BMW ne seront plus livrés avec un schéma de l\'emplacement des fusibles sous format papier. En cas de besoin, vous pouvez néanmoins ici télécharger le schéma de l\'emplacement des fusibles de votre véhicule sous forme de document PDF.',
        subheadline: 'Veuillez saisir le numéro d\'identification de votre véhicule',
        'form-example': 'Veuillez saisir le VIN (par ex. WBS1H9C36HV888591)',
        'button-dl': 'Télécharger le PDF',
        'data-info': 'Veuillez saisir les 17 chiffres du numéro d\'identification de votre véhicule BMW (VIN) (par ex. WMBHA61000BB07121). Le VIN se trouve inscrit sur le certificat d\'immatriculation, dans le compartiment moteur ou sur le pare-brise en bas à gauche.',
        'no-pdf-for-vin': 'Malheureusement, aucun schéma de l\'emplacement des fusibles correspondant au numéro d\'identification de votre véhicule n\'est disponible en ligne. Veuillez vous adresser à votre partenaire BMW.',
        'no-vehicle-for-vin': 'Le numéro d\'identification de votre véhicule ne correspond malheureusement à aucun véhicule BMW. Veuillez vérifier vos données.',
        'server-error': 'Nous ne sommes actuellement malheureusement pas en mesure de traiter votre requête. Veuillez réessayer ultérieurement.',
    },
    es: {
        headline: 'Su esquema de fusibles',
        copy: 'Los vehículos BMW construidos en 2019 o años posteriores ya no incluyen su esquema de fusibles en papel. Si lo desea, aquí puede descargar el esquema de fusibles de su vehículo como documento PDF.',
        subheadline: 'Introduzca el número de bastidor de su vehículo',
        'form-example': 'Introduzca el VIN (ejemplo: WBS1H9C36HV888591)',
        'button-dl': 'Descargar PDF',
        'data-info': 'Introduzca el número de bastidor de 17 cifras (VIN) de su BMW (ejemplo: WMBHA61000BB07121). El número de bastidor se encuentra en el permiso de circulación, en el compartimento del motor o abajo a la izquierda en el parabrisas.',
        'no-pdf-for-vin': 'Lo sentimos, pero no hay disponible ningún esquema de fusibles online para el número de bastidor facilitado. Póngase en contacto con su distribuidor de BMW.',
        'no-vehicle-for-vin': 'Lo sentimos, pero no hemos podido identificar el número de bastidor con ningún vehículo BMW. Compruebe los datos introducidos.',
        'server-error': 'Lo sentimos, pero en este momento no podemos procesar su solicitud. Inténtelo de nuevo más tarde.',
    },
    it: {
        headline: 'Il suo schema della scatola fusibili',
        copy: 'Dall\'anno di costruzione 2019 i veicoli BMW non saranno più dotati dello schema della scatola fusibili in forma cartacea. Se necessario, può scaricare qui lo schema relativo alla sua auto come documento PDF.',
        subheadline: 'Inserire il numero identificativo del veicolo (VIN)',
        'form-example': 'Inserire il VIN (p. es. WBS1H9C36HV888591)',
        'button-dl': 'Scaricare lo schema della scatola fusibili',
        'data-info': 'Inserire l\'intero numero identificativo del veicolo composto di 17 cifre (VIN) della sua BMW (p. es. WMBHA61000BB07121). Il VIN è indicato nel libretto di circolazione, nel vano motore o sul parabrezza a sinistra in basso.',
        'no-pdf-for-vin': 'Siamo spiacenti, ma per il numero identificativo del veicolo indicato lo schema della scatola fusibili non è disponibile online. La preghiamo di rivolgerti (rivolgersi) al suo rivenditore BMW.',
        'no-vehicle-for-vin': 'Siamo spiacenti, ma il numero identificativo del veicolo non corrisponde a nessun veicolo BMW. La preghiamo di verificare il numero immesso.',
        'server-error': 'Siamo spiacenti, ma attualmente la sua richiesta non può essere elaborata. Riprovi più tardi.',
    },
    pt: {
        headline: 'O seu plano de atribuição de fusíveis',
        copy: 'O plano de atribuição de fusíveis deixa de ser anexo no formato de papel para veículos da marca BMW a partir do ano de construção de 2019. Em caso de necessidade poderá descarregar aqui o plano de atribuição de fusíveis para o seu veículo, como documento PDF.',
        subheadline: 'Introduza o seu número de identificação de veículo',
        'form-example': 'Introduza o NIV (por exemplo WBS1H9C36HV888591)',
        'button-dl': 'Descarregar o plano',
        'data-info': 'Introduza os 17 caracteres do número de identificação do veículo (NIV) do seu BMW (por exemplo WMBHA61000BB07121). O NIV encontra-se no Documento Único Automóvel, no compartimento do motor ou no canto esquerdo do para-brisas.',
        'no-pdf-for-vin': 'Infelizmente, não temos nenhum plano de atribuição de fusíveis online disponível para o número de identificação de veículo indicado. Contacte o seu parceiro BMW.',
        'no-vehicle-for-vin': 'Infelizmente não conseguimos atribuir o número de identificação do veículo a um veículo da BMW. Verifique os dados introduzidos.',
        'server-error': 'Infelizmente não nos é possível processar o seu pedido neste momento. Tente novamente mais tarde.',
    },
    da: {
        headline: 'Din sikringsoversigt',
        copy: 'Fra og med byggeåret 2019 vedlægges der ikke længere sikringsoversigter i papirform til BMW-biler. Hvis du har brug for sikringsoversigten til din bil, kan du downloade den her som PDF-dokument.',
        subheadline: 'Indtast dit køretøjsidentifikationsnummer',
        'form-example': 'Indtast VIN (f.eks. WBS1H9C36HV888591)',
        'button-dl': 'Download PDF',
        'data-info': 'Indtast hele det 17-cifrede køretøjsidentifikationsnummer (VIN) til din BMW (f.eks. WMBHA61000BB07121). VIN-nummeret findes på registreringsattesten, i motorrummet eller nederst til venstre på forruden.',
        'no-pdf-for-vin': 'Der er desværre ingen tilgængelige online-sikringsoversigter for det angivne køretøjsidentifikationsnummer. Kontakt din BMW-partner.',
        'no-vehicle-for-vin': 'Desværre kunne vi ikke tilordne køretøjsidentifikationsnummeret til nogen BMW-bil. Kontrollér din indtastning.',
        'server-error': 'Desværre kan vi ikke behandle din forespørgsel i øjeblikket. Prøv igen senere.',
    },
};

export default i18n;
